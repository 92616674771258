import { defineMessages } from 'react-intl';

export default defineMessages({
  PRE_MARKETING: { id: 'home.marketing.preMessage', defaultMessage: 'The platform that connects users & producers of digital solutions' },
  SHARE: { id: 'home.marketing.share.name', defaultMessage: 'Share' },
  CONTRIBUTE: { id: 'home.marketing.contribute.name', defaultMessage: 'Contribute' },
  INSPIRE: { id: 'home.marketing.inspire.name', defaultMessage: 'Inspire' },
  SHARE_TEXT: { id: 'home.marketing.share.text', defaultMessage: 'Tangram is the perfect place to publish all information related to digital initiatives and products.' },
  CONTIBUTE_TEXT: { id: 'home.marketing.contribute.text', defaultMessage: 'Your voice counts! Promote continuous improvement of digital products by expressing your opinion on the applications you use daily.' },
  INSPIRE_TEXT: { id: 'home.marketing.inspire.text', defaultMessage: 'Our professions are constantly evolving. With Tangram, identify initiatives that speak to you or digital solutions that can meet your needs.' },
});
